import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import Button from "@material-ui/core/Button";
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from "recharts";
// import ArrowRightIcon from "@material-ui/icons/ArrowRight";
// import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
// import { IconButton } from "@material-ui/core";
import ScrollToBottom from "react-scroll-to-bottom";
import { API_URL } from "../helpers/url";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
// import SendIcon from "@material-ui/icons/Send";
// import FilledInput from "@material-ui/core/FilledInput";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles({
  paper: {
    width: "47%",
    margin: "8px",
    padding: "4px",
  },
  heartbeatGraph: {
    width: "100%",
    margin: "8px",
    padding: "4px",
  },
  graphContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    flex: 8,
    height: "100%",
  },
  networkContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    flex: 1,
    margin: "8px",
  },
  logMessage: {
    overflow: "scroll",
    height: "100%",
    padding: "8px",
    backgroundColor: "black",
    border: "#272626 8px solid",
    borderRadius: "4px",
    maxWidth: "1090px",
    wordBreak: "break-word",
  },
});

// const ChartControls = () => (
//   <div
//     style={{
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "space-around",
//     }}
//   >
//     <IconButton>
//       <ArrowLeftIcon />
//     </IconButton>
//     <Button size="small">Go Back to Live</Button>
//     <IconButton>
//       <ArrowRightIcon />
//     </IconButton>
//   </div>
// );

const ShutdownServerModal = ({ onYes, onCancel, open }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Shutdown Server</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to shutdown this server?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onYes();
            onCancel();
          }}
          color="secondary"
        >
          Yes
        </Button>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Chart = ({ className, data, domain, title, height }) => (
  <Paper className={className} variant="outlined">
    <Typography align="center">{title}</Typography>
    <ResponsiveContainer style={{ zIndex: 5 }} width="100%" height={height}>
      <LineChart data={data} margin={graphMargin}>
        <XAxis
          dataKey="name"
          style={{
            fill: "rgba(255, 255, 255, 1)",
            textAnchor: "end",
            fontSize: "90%",
          }}
          height={60}
          angle={-10}
        />
        <YAxis
          style={{
            fill: "rgba(255, 255, 255, 1)",
          }}
          domain={domain}
        />
        <Tooltip
          labelStyle={{ color: "white" }}
          itemStyle={{ color: "white" }}
          contentStyle={{
            backgroundColor: "#272626",
            borderRadius: "4px",
            borderColor: "rgba(255, 255, 255, 0.12)",
            borderWidth: "1px",
          }}
        />
        <Line type="monotone" dataKey="value" stroke="#fff" yAxisId={0} />
        <Line
          type="monotone"
          dataKey="max_value"
          stroke="#ff7300"
          yAxisId={0}
        />
      </LineChart>
    </ResponsiveContainer>
    {/* <ChartControls /> */}
  </Paper>
);

const graphMargin = { top: 5, right: 5, left: 5, bottom: 0 };

const formatTimeToMinusSeconds = (dateString) =>
  new Date(dateString).toLocaleTimeString();

const convertObjectToStringArray = (obj, key, max_key = "") =>
  obj
    .map((item) => ({
      name: formatTimeToMinusSeconds(item["created_at"]),
      value: item[key],
      max_value: item[max_key],
    }))
    .reverse();

const Monitor = ({ selectedServer, gameId }) => {
  const classes = useStyles();

  const [memory, setMemory] = useState();
  const [heartbeat, setHeartbeat] = useState();
  const [physics, setPhysics] = useState();
  const [movingInstances, setMovingInstances] = useState();

  const [dataReceive, setDataReceive] = useState();
  const [dataSend, setDataSend] = useState();
  const [physicsReceive, setPhysicsReceive] = useState();
  const [physicsSend, setPhysicsSend] = useState();

  const [players, setPlayers] = useState([]);
  const [logMessages, setLogMessages] = useState([]);

  const [shutdownModalOpen, setShutdownModalOpen] = useState(false);

  // const [command, setCommand] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const [userid, setUserid] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendCommand = (userid, command) => {
    if (selectedServer !== "home") {
      fetch(`${API_URL}/client/command`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid,
          command,
          serverId: selectedServer,
        }),
      });
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateGraphs = () => {
      fetch(
        selectedServer !== "home"
          ? `${API_URL}/client/data?serverId=${selectedServer}`
          : `${API_URL}/client/home?gameId=${gameId}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.length !== 0) {
            if (selectedServer !== "home") {
              setPlayers(
                JSON.parse(result.data[result.data.length - 1].players)
              );
              setLogMessages(
                result.data.reduce((prev, { console_messages }) => {
                  const new_console_messages = console_messages || "[]";
                  return [...prev, ...JSON.parse(new_console_messages)];
                }, [])
              );

              setMemory(convertObjectToStringArray(result.data, "memory"));
              setHeartbeat(
                convertObjectToStringArray(result.data, "heartbeat_time")
              );
              setPhysics(
                convertObjectToStringArray(result.data, "physics_time")
              );
              setMovingInstances(
                convertObjectToStringArray(result.data, "moving_instance")
              );

              setDataReceive(
                convertObjectToStringArray(result.data, "data_receive_kbps")
              );
              setDataSend(
                convertObjectToStringArray(result.data, "data_send_kbps")
              );
              setPhysicsReceive(
                convertObjectToStringArray(result.data, "physics_receive")
              );
              setPhysicsSend(
                convertObjectToStringArray(result.data, "physics_send")
              );

              setLoading(false);
            } else if (selectedServer === "home") {
              setMemory(
                convertObjectToStringArray(
                  result.data,
                  "avg_memory",
                  "max_memory"
                )
              );
              setHeartbeat(
                convertObjectToStringArray(
                  result.data,
                  "avg_heartbeat_time",
                  "max_heartbeat_time"
                )
              );
              setPhysics(
                convertObjectToStringArray(
                  result.data,
                  "avg_physics_time",
                  "max_physics_time"
                )
              );
              setMovingInstances(
                convertObjectToStringArray(
                  result.data,
                  "avg_moving_instance",
                  "max_moving_instance"
                )
              );

              setDataReceive(
                convertObjectToStringArray(
                  result.data,
                  "avg_data_receive_kbps",
                  "max_data_receive_kbps"
                )
              );
              setDataSend(
                convertObjectToStringArray(
                  result.data,
                  "avg_data_send_kbps",
                  "max_data_send_kbps"
                )
              );
              setPhysicsReceive(
                convertObjectToStringArray(
                  result.data,
                  "avg_physics_receive",
                  "max_physics_receive"
                )
              );
              setPhysicsSend(
                convertObjectToStringArray(
                  result.data,
                  "avg_physics_send",
                  "max_physics_send"
                )
              );

              setLoading(false);
            }
          }
        });
    };
    updateGraphs();
    const interval = setInterval(updateGraphs, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServer]);

  useEffect(() => {
    setLoading(true);
    setLogMessages([]);
  }, [selectedServer]);

  console.log(memory);

  return (
    <>
      {loading || (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "calc(100% - 64)",
          }}
        >
          <ShutdownServerModal
            open={shutdownModalOpen}
            onCancel={() => setShutdownModalOpen(false)}
            onYes={() => sendCommand("", "shutdown")}
          />
          <div className={classes.graphContainer}>
            <Paper className={classes.networkContainer}>
              <Chart
                className={classes.paper}
                data={dataReceive}
                domain={[0, 0.5]}
                title="Data Receive"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={dataSend}
                domain={[0, 0.5]}
                title="Data Send"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={physicsReceive}
                domain={[0, 0.5]}
                title="Physics Receive"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={physicsSend}
                domain={[0, 0.5]}
                title="Physics Send"
                height={200}
              />
            </Paper>
            <Paper className={classes.networkContainer}>
              <Chart
                className={classes.paper}
                data={memory}
                domain={[0, 3000]}
                title="Memory Usage"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={heartbeat}
                domain={[0, 1]}
                title="Heartbeat Time"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={physics}
                domain={[0, 1]}
                title="Physics Time"
                height={200}
              />
              <Chart
                className={classes.paper}
                data={movingInstances}
                domain={[0, 100]}
                title="Moving Objects"
                height={200}
              />
            </Paper>
            {selectedServer !== "home" && (
              <Paper
                style={{
                  width: "100%",
                  height: "350px",
                  margin: "8px",
                  marginTop: "4px",
                }}
              >
                <ScrollToBottom className={classes.logMessage}>
                  {logMessages.length > 0 &&
                    logMessages.map(({ text, color }) => (
                      <Typography style={{ color: color, marginBottom: "2px" }}>
                        {text}
                      </Typography>
                    ))}
                </ScrollToBottom>
                {/* TODO commands */}
                {/* <FormControl fullWidth variant="standard">
                  <FilledInput
                    type={"text"}
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl> */}
              </Paper>
            )}
          </div>
          {selectedServer !== "home" && (
            <div
              style={{
                flex: 2,
                margin: "8px",
                marginBottom: "0",
                maxWidth: "348px",
              }}
            >
              <Paper
                style={{
                  flex: 1,
                  height: "calc(100vh - 160px)",
                  overflow: "scroll",
                }}
              >
                <Typography variant="h5" align="center">
                  Player List
                </Typography>
                <List
                  fullwidth
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    overflow: "scroll",
                  }}
                >
                  {players.map(({ userid, username, displayname }) => (
                    <ListItem
                      style={{ wordBreak: "break-all", minWidth: "286px" }}
                    >
                      <ListItemText
                        primary={"@" + username + "/" + displayname}
                        secondary={userid}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(e);
                            setUserid(userid);
                          }}
                          edge="end"
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      sendCommand(userid, "kick");
                      handleClose();
                    }}
                  >
                    Kick
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      sendCommand(userid, "ban");
                      handleClose();
                    }}
                  >
                    Ban
                  </MenuItem>
                </Menu>
              </Paper>
              <Paper style={{ padding: "8px", marginTop: "8px" }}>
                <Button
                  fullWidth
                  onClick={() => setShutdownModalOpen(true)}
                  variant="contained"
                  color="secondary"
                >
                  Shut Down Server
                </Button>
              </Paper>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Monitor;
