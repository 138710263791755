import { Button, Container, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { API_URL } from "../helpers/url";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");

  const register = () => {
    setError("");
    if (password === password2 && password && password2 && email) {
      fetch(`${API_URL}/auth/signup`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          email,
        }),
      }).then((result) => {
        if (result.status === 200) {
          window.location.replace("/");
        } else {
          setError("Somethings wrong, please try again");
        }
      });
    } else if (password !== password2) {
      setError("passwords don't match");
    } else if (email === "" || password === "" || password2 === "") {
      setError("Please fill out all fields");
    }
  };

  return (
    <Container
      maxWidth="xs"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h3">Reblox Registration</Typography>
      <TextField
        label="Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <TextField
        label="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
      />
      <TextField
        label="Password Confirmation"
        onChange={(e) => setPassword2(e.target.value)}
        value={password2}
        type="password"
      />
      <Button onClick={register}>Register</Button>
      <Button href="/">Go back to Login</Button>
      <Typography color="error">{error}</Typography>
    </Container>
  );
};

export default Register;
