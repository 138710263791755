import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Toolbar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { API_URL } from "../helpers/url";

const createCheckoutSession = (e, lookupKey) => {
  e.preventDefault();
  fetch(`${API_URL}/client/create-checkout-session`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      lookup_key: lookupKey,
    }),
  })
    .then((result) => result.json())
    .then((result) => {
      if (result.url) {
        window.location.replace(result.url);
      }
    });
};

const ProductDisplay = ({ title, price, lookupKey }) => (
  <Card style={{ width: "400px", margin: "16px" }}>
    <CardHeader title={title} subheader={`${price} USD / month`} />
    <CardActions>
      <Button onClick={(e) => createCheckoutSession(e, lookupKey)}>
        Checkout
      </Button>
    </CardActions>
  </Card>
);

const createPortalSession = (e) => {
  e.preventDefault();
  fetch(`${API_URL}/client/create-portal-session`, {
    method: "POST",
    credentials: "include",
  })
    .then((result) => result.json())
    .then((result) => {
      if (result.url) {
        window.location.replace(result.url);
      }
    });
};

const SuccessDisplay = ({ sessionId }) => {
  return (
    <Card style={{ width: "400px", margin: "16px" }}>
      <CardHeader title="Subscription to plan successful!" />
      <CardActions>
        <Button onClick={(e) => createPortalSession(e, sessionId)}>
          Manage your billing information
        </Button>
      </CardActions>
    </Card>
  );
};

export default function Payments() {
  let [plan, setPlan] = useState("");

  useEffect(() => {
    fetch(`${API_URL}/client/get-subscription`, {
      method: "POST",
      credentials: "include",
    })
      .then((result) => result.json())
      .then((result) => {
        setPlan(result.plan);
      });
  }, []);

  if (plan === "" || plan === null) {
    return (
      <>
        <Toolbar />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProductDisplay title="Basic" price="10.00" lookupKey="bas" />
          <ProductDisplay title="Premium" price="20.00" lookupKey="prem" />
          <ProductDisplay title="Professional" price="30.00" lookupKey="pro" />
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Toolbar />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SuccessDisplay />
        </Container>
      </>
    );
  }
}
