import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ForgotPassword from "../screens/ForgotPassword";
import Login from "../screens/Login";
import Register from "../screens/Register";
import ResetPassword from "../screens/ResetPassword";
import ValidateEmail from "../screens/ValidateEmail";

const AuthRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/forgot/:emailCode" element={<ResetPassword />} />
        <Route path="/validate/:emailCode" element={<ValidateEmail />} />
      </Routes>
    </Router>
  );
};

export default AuthRoutes;
