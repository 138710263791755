import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { API_URL } from "../helpers/url";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../media/logo_transparent.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const TopAppBar = () => {
  const classes = useStyles();

  const logout = () => {
    fetch(`${API_URL}/auth/logout`, {
      method: "get",
      credentials: "include",
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
          <img alt="reblox" src={Logo} style={{ height: "50px" }} />
        </Typography>
        <Button href="/">Home</Button>
        <Button href="/payments">Payments</Button>
        <Button onClick={logout}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
