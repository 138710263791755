import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TopAppBar from "../components/TopAppBar";
import Navigation from "../Navigation";
import Payments from "../screens/Payments";
import ValidateEmail from "../screens/ValidateEmail";

const MainRoutes = () => {
  return (
    <Router>
      <TopAppBar />
      <Routes>
        <Route path="/" element={<Navigation />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/validate/:emailCode" element={<ValidateEmail />} />
      </Routes>
    </Router>
  );
};

export default MainRoutes;
