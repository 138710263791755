import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Monitor from "./screens/Monitor";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { API_URL } from "./helpers/url";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
  },
}));

const AddGameDialog = ({
  gameName,
  setGameName,
  apiKey,
  setApiKey,
  addGameModalOpen,
  setAddGameModalOpen,
}) => {
  const handleClose = () => {
    setAddGameModalOpen(false);
    window.location.reload();
  };

  const createGame = () => {
    fetch(`${API_URL}/client/games`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gameName,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setApiKey(result.data.api_key);
      });
  };

  return (
    <Dialog
      open={addGameModalOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Game</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the game name and click the button below to create an API
          key for your game
        </DialogContentText>
        <TextField
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
          margin="dense"
          label="Game Name"
          fullWidth
        />
        <Button onClick={createGame}>Create API Key</Button>
        {apiKey && (
          <>
            <DialogContentText>
              You can now copy and paste the key below into your lua config
            </DialogContentText>
            <TextField fullWidth disabled value={apiKey} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Navigation = () => {
  const classes = useStyles();

  const [servers, setServers] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);

  const [addGameModalOpen, setAddGameModalOpen] = useState(false);

  const [apiKey, setApiKey] = useState(null);
  const [gameName, setGameName] = useState("");

  useEffect(() => {
    fetch(`${API_URL}/client/games`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        setGames(result.data);
        if (result.data.length > 0) {
          setSelectedGame(result.data[0]);
        } else {
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    const getServerList = async () =>
      selectedGame &&
      fetch(`${API_URL}/client/servers?game_id=${selectedGame.id}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          setServers(result.data);
          if (selectedServer?.length <= 0 && result.data.length > 0) {
            setSelectedServer(result.data[0].name);
          }
        });
    if (selectedGame) {
      setLoading(true);
      getServerList();
      setLoading(false);
      const interval = setInterval(getServerList, 5000);
      return () => clearInterval(interval);
    }
  }, [selectedGame, selectedServer?.length]);

  return (
    <>
      {!loading && (
        <div className={classes.root}>
          <AddGameDialog
            gameName={gameName}
            setGameName={setGameName}
            apiKey={apiKey}
            setApiKey={setApiKey}
            addGameModalOpen={addGameModalOpen}
            setAddGameModalOpen={setAddGameModalOpen}
          />
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <Button
              onClick={() => {
                setAddGameModalOpen(true);
                setApiKey("");
                setGameName("");
              }}
              variant="outlined"
              color="primary"
              style={{ margin: "8px" }}
            >
              Add Game
            </Button>
            <FormControl style={{ margin: "12px" }}>
              <Select
                value={selectedGame}
                onChange={(e) => setSelectedGame(e.target.value)}
              >
                {games.map((game) => (
                  <MenuItem value={game}>{game.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.drawerContainer}>
              <List>
                <ListItem
                  selected={"home" === selectedServer}
                  button
                  onClick={() => setSelectedServer("home")}
                >
                  <ListItemText
                    primary={"Home"}
                    secondary={`Total Players: ${
                      servers && servers.length > 0
                        ? servers.reduce(
                            (prev, { player_count }) => prev + player_count,
                            0
                          )
                        : 0
                    }`}
                  />
                </ListItem>
                {servers &&
                  servers.length > 0 &&
                  servers
                    .sort((a, b) => b.active - a.active)
                    .map(({ name, active, player_count, geoData }) => (
                      <ListItem
                        selected={name === selectedServer}
                        button
                        onClick={() => setSelectedServer(name)}
                        style={{
                          color: active ? "green" : "red",
                        }}
                      >
                        <ListItemText
                          primary={name}
                          secondary={
                            <div>
                              <Typography>Players: {player_count}</Typography>
                              {geoData && (
                                <Typography>
                                  {geoData.country} - {geoData.region} -{" "}
                                  {geoData.city}
                                </Typography>
                              )}
                            </div>
                          }
                        />
                      </ListItem>
                    ))}
              </List>
            </div>
          </Drawer>
          {selectedServer ? (
            <main className={classes.content}>
              <Toolbar />
              <Monitor
                selectedServer={selectedServer}
                gameId={selectedGame.id}
              />
            </main>
          ) : (
            <main className={classes.content}>
              <Toolbar />
              <Typography
                style={{ marginTop: "24px" }}
                variant="h5"
                align="center"
              >
                Add a game and/or select a server to view data
              </Typography>
              <Typography style={{ marginTop: "24px" }} align="center">
                Visit the payments link above to subscribe to a plan, if
                necessary.
              </Typography>
            </main>
          )}
        </div>
      )}
    </>
  );
};

export default Navigation;
