import { Container, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { API_URL } from "../helpers/url";
import { useParams } from "react-router-dom";

const ValidateEmail = () => {
  const [error, setError] = useState("");
  let { emailCode } = useParams();

  useEffect(() => {
    setError("");
    if (emailCode) {
      fetch(`${API_URL}/auth/validate-email`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailCode,
        }),
      })
        .then((result) => {
          if (result.status === 200) {
            window.location.replace("/");
          } else if (result.status === 401) {
            setError("Incorrect code");
          }
        })
        .catch((err) => setError(err.message));
    } else {
      setError("missing code");
    }
  }, [emailCode]);

  return (
    <Container
      maxWidth="xs"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h3">Reblox</Typography>
      <Typography variant="h5" style={{ marginTop: "40px" }}>
        Validating email
      </Typography>
      {
        <Typography color="error" style={{ marginTop: "40px" }}>
          {error}
        </Typography>
      }
    </Container>
  );
};

export default ValidateEmail;
