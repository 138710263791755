import { Button, Container, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { API_URL } from "../helpers/url";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const login = () => {
    setError("");
    if (password && email) {
      fetch(`${API_URL}/auth/login`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          email,
        }),
      })
        .then((result) => {
          if (result.status === 200) {
            window.location.replace("/");
          } else if (result.status === 401) {
            setError("Incorrect email or password");
          }
        })
        .catch((err) => setError(err.message));
    } else {
      setError("Please fill out username and password");
    }
  };

  return (
    <Container
      maxWidth="xs"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h3">Reblox</Typography>
      <TextField
        label="Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <TextField
        label="Password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
      />
      <Button onClick={login} variant="contained">
        Login
      </Button>
      <Button href="register">Register</Button>
      <Button href="forgot">Forgot Password</Button>
      {<Typography color="error">{error}</Typography>}
    </Container>
  );
};

export default Login;
