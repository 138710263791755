import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AuthRoutes from "./routers/AuthRoutes";
import { API_URL } from "./helpers/url";
import MainRoutes from "./routers/MainRoutes";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#4092a0",
    },
    secondary: {
      main: "#239cab",
    },
    background: {
      default: "#0c0c0c",
      paper: "#272626",
    },
  },
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/client/`, {
      method: "get",
      credentials: "include",
    })
      .then((result) => {
        if (result.status === 200) {
          setIsLoggedIn(true);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isLoggedIn ? <MainRoutes /> : <AuthRoutes />}
    </ThemeProvider>
  );
};

export default App;
